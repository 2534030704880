<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <div style="display: flex">
        <el-input placeholder="请输入国家" v-model="country"></el-input>
        <el-input placeholder="请输入城市" v-model="city"></el-input>

        <div>
          <el-button type="primary" @click="searchList">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="handleAdd">添加城市</el-button>

        <el-dialog title="添加城市" :visible.sync="dialogVisible">
          <div class="dialog_dis">
            <div>
              <el-input
                placeholder="请输入国家(中文)"
                v-model="countryZh"
              ></el-input>
              <el-input
                placeholder="请输入国家(日文)"
                v-model="countryJp"
              ></el-input>
              <el-input
                placeholder="请输入国家(韩文)"
                v-model="countryKr"
              ></el-input>
              <el-input
                placeholder="请输入国家(繁体)"
                v-model="countryTw"
              ></el-input>
              <el-input
                placeholder="请输入国家(英文)"
                v-model="countryUs"
              ></el-input>
            </div>
            <div>
              <el-cascader
                filterable
                v-model="value"
                ref="cascader"
                :options="optionsCity"
                placeholder="可搜索关键词（如：北京）"
                @change="handleChangeCity"
              ></el-cascader>

              <el-input
                placeholder="请输入城市(日文)"
                v-model="cityJp"
              ></el-input>
              <el-input
                placeholder="请输入城市(韩文)"
                v-model="cityKr"
              ></el-input>
              <el-input
                placeholder="请输入城市(繁体)"
                v-model="cityTw"
              ></el-input>
              <el-input
                placeholder="请输入城市(英文)"
                v-model="cityUs"
              ></el-input>
            </div>
          </div>
          <div>
            <el-input
              placeholder="请输入钱包地址"
              v-model="wallet"
              style="width: 100%"
            ></el-input>
          </div>
          <div style="margin-top: 20px">
            是否为默认城市 <el-switch v-model="isDefault"></el-switch>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleAddCity">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="country" label="国家"> </el-table-column>
        <el-table-column prop="city" label="城市"> </el-table-column>
        <el-table-column prop="wallet" label="收款钱包地址" width="700">
        </el-table-column>
        <el-table-column prop="isDefault" label=" 是否为默认城市">
          <template slot-scope="scope">
            {{ scope.row.isDefault ? "是" : "否" }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, 'update')"
              type="text"
              size="small"
              >修改</el-button
            >
            <el-button
              @click="handleClick(scope.row, 'delete')"
              type="text"
              size="small"
              style="color: #f56c6c"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="修改城市" :visible.sync="dialogVisibleUpdate">
        <div class="dialog_dis">
          <div>
            <el-input
              placeholder="请输入国家(中文)"
              v-model="update.country"
            ></el-input>
            <el-input
              placeholder="请输入国家(日文)"
              v-model="update.countryJp"
            ></el-input>
            <el-input
              placeholder="请输入国家(韩文)"
              v-model="update.countryKr"
            ></el-input>
            <el-input
              placeholder="请输入国家(繁体)"
              v-model="update.countryTw"
            ></el-input>
            <el-input
              placeholder="请输入国家(英文)"
              v-model="update.countryUs"
            ></el-input>
          </div>
          <div>
            <el-cascader
              filterable
              ref="cascader"
              :options="optionsCity2"
              placeholder="可搜索关键词（如：北京）"
              @change="handleChangeCity"
              v-model="update.city"
            ></el-cascader>
            <el-input
              placeholder="请输入城市(日文)"
              v-model="update.cityJp"
            ></el-input>
            <el-input
              placeholder="请输入城市(韩文)"
              v-model="update.cityKr"
            ></el-input>
            <el-input
              placeholder="请输入城市(繁体)"
              v-model="update.cityTw"
            ></el-input>
            <el-input
              placeholder="请输入城市(英文)"
              v-model="update.cityUs"
            ></el-input>
          </div>
        </div>
        <div>
          <el-input
            placeholder="请输入钱包地址"
            v-model="update.wallet"
            style="width: 100%"
          ></el-input>
        </div>
        <div style="margin-top: 20px">
          是否为默认城市
          <el-switch v-model="update.isDefault"></el-switch>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleUpdate = false">取 消</el-button>
          <el-button type="primary" @click="handleUpdate">确 定</el-button>
        </span>
      </el-dialog>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";
// import { areaCityData } from "../../../util/areaCity";

import { pcaa } from "area-data";
import allCityData from "../../../api/city.json";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "城市管理",
        },
      ], // 面包屑数据

      cityData: [],

      city: "",
      country: "",
      cities: [],
      activeName: "",
      value: [],

      cityZh: "",
      cityJp: "",
      cityKr: "",
      cityTw: "",
      cityUs: "",
      countryZh: "",
      countryJp: "",
      countryKr: "",
      countryTw: "",
      countryUs: "",
      wallet: "",
      isDefault: false,
      optionsCity: [],
      optionsCity2: [],
      cityName: "",
      cityLat: "",
      cityLon: "",
      districtId: "",
      dialogVisibleCity: false,

      update: {
        city: "",
        cityJp: "",
        cityKr: "",
        cityTw: "",
        cityUs: "",
        country: "",
        countryJp: "",
        countryKr: "",
        countryTw: "",
        countryUs: "",
        wallet: "",
        isDefault: false,
      },
      rowId: "",

      tableData: [],
      dialogVisible: false,
      dialogVisibleUpdate: false,

      // proviceList: pcaa["86"], //  获取所有的省
      // pcaaData: pcaa,
      // cityList: [],
      // countyList: [],
      // form: {
      //   province: "",
      //   city: "",
      // },

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.optionsCity = this.setAllCity(allCityData);

    showLoading();
    const opt = {
      url: reqUrl.worldTravelSelectCity,
      method: "POST",
      params: JSON.stringify({
        city: "",
        country: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelSelectCity,
        method: "POST",
        params: JSON.stringify({
          city: this.city,
          country: this.country,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handleAdd() {
      this.dialogVisible = true;
    },

    setAllCity(data) {
      // let arr = [];
      // for (const key in data) {
      //   let cityObj = {};
      //   let cityInfo = data[key];
      //   cityObj.value = key || "";
      //   cityObj.label = key || "";
      //   cityObj.children = [];
      //   for (const ikey in cityInfo) {
      //     let province = {};
      //     let provinceInfo = cityInfo[ikey];
      //     province.value = ikey || "";
      //     province.label = ikey || "";
      //     province.children = [];
      //     for (const jkey in provinceInfo) {
      //       province.children.push({
      //         value: jkey || "",
      //         label: jkey || "",
      //         lon: provinceInfo[jkey][0].lon || "",
      //         lat: provinceInfo[jkey][0].lat || "",
      //         id: provinceInfo[jkey][0].districtId || "",
      //       });
      //     }
      //     cityObj.children.push(province);
      //   }
      //   arr.push(cityObj);
      // }
      // return arr;

      let arr = [];
      for (const key in data) {
        let cityObj = {};
        let cityInfo = data[key];
        cityObj.value = key || "";
        cityObj.label = key || "";
        cityObj.children = [];
        if (cityObj.value == "中国") {
          for (const ikey in cityInfo) {
            let province = {};
            let provinceInfo = cityInfo[ikey];
            province.value = ikey || "";
            province.label = ikey || "";
            province.children = [];
            for (const jkey in provinceInfo) {
              province.children.push({
                value: jkey || "",
                label: jkey || "",
                lon: provinceInfo[jkey][0].lon || "",
                lat: provinceInfo[jkey][0].lat || "",
                id: provinceInfo[jkey][0].districtId || "",
              });
            }
            cityObj.children.push(province);
          }
        } else {
          for (const jkey in cityInfo) {
            cityObj.children.push({
              value: jkey || "",
              label: jkey || "",
              lon: cityInfo[jkey][0].lon || "",
              lat: cityInfo[jkey][0].lat || "",
              id: cityInfo[jkey][0].districtId || "",
            });
          }
        }

        arr.push(cityObj);
      }
      return arr;
    },

    handleChangeCity(value) {
      const data = this.setAllCity(allCityData);
      console.log(data, "nodesInfonodesInfo");
      let dataObj = {};
      data.forEach((item, inx) => {
        if (item.value === value[0]) {
          let itemArr = item.children || [];
          itemArr.forEach((jtem) => {
            if (jtem.value === value[1]) {
              let jtemArr = jtem.children || [];
              if (value[2]) {
                jtemArr.forEach((ktem) => {
                  if (ktem.value === value[2]) {
                    dataObj = ktem;
                  }
                });
              } else {
                dataObj = jtem || {};
              }
            }
          });
        }
      });
      this.cityLat = dataObj.lat;
      this.cityLon = dataObj.lon;
      this.cityName = dataObj.label;
      this.districtId = dataObj.id;
    },

    handleAddCity() {
      if (this.cityZh !== "" || this.countryZh !== "" || this.wallet !== "") {
        showLoading();
        const opt = {
          url: reqUrl.worldTravelInsertCity,
          method: "POST",
          params: JSON.stringify({
            city: this.cityName,
            cityJp: this.cityJp,
            cityKr: this.cityKr,
            cityTw: this.cityTw,
            cityUs: this.cityUs,
            country: this.countryZh,
            countryJp: this.countryJp,
            countryKr: this.countryKr,
            countryTw: this.countryTw,
            countryUs: this.countryUs,
            wallet: this.wallet,
            isDefault: this.isDefault,
            districtId: this.districtId,
            location: this.cityLat + "," + this.cityLon,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.$message.success("添加成功");
              this.refresh();
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$message.error("请检查填写信息是否完整");
      }
    },

    handleClick(row, type) {
      if (type == "delete") {
        this.$confirm("您确定要删除该城市吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.worldTravelDeleteCity,
            method: "POST",
            params: JSON.stringify({
              id: row.id,
            }),
            resFunc: (res) => {
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message.success("删除成功");
                this.refresh();
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
            },
          };
          request(opt);
        });
      } else if (type == "update") {
        this.optionsCity = this.setAllCity(allCityData);
        this.optionsCity2 = this.setAllCity(allCityData);
        this.dialogVisibleUpdate = true;

        const { createTime, updateTime, id, ...newUpdate } = row;
        this.update = newUpdate;
        this.cityName = row.city;
        this.rowId = row.id;
      }
    },

    handleUpdate() {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelUpdateCity,
        method: "POST",
        params: JSON.stringify({
          id: this.rowId,
          city: this.cityName,
          cityJp: this.update.cityJp,
          cityKr: this.update.cityKr,
          cityTw: this.update.cityTw,
          cityUs: this.update.cityUs,
          country: this.update.country,
          countryJp: this.update.countryJp,
          countryKr: this.update.countryKr,
          countryTw: this.update.countryTw,
          countryUs: this.update.countryUs,
          wallet: this.update.wallet,
          isDefault: this.update.isDefault,
          districtId: this.districtId,
          location: this.cityLat + "," + this.cityLon,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.$message.success("修改成功");
            this.refresh();
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.worldTravelSelectCity,
        method: "POST",
        params: JSON.stringify({
          city: this.city,
          country: this.country,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
        },
      };
      request(opt);
    },

    reset() {
      this.city = "";
      this.country = "";
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  justify-content: space-between;
  .el-input {
    width: 300px;
    margin-right: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}

.dialog_dis {
  display: flex;

  .el-input,
  .el-cascader {
    margin-bottom: 10px;
    width: 99%;
  }

  .el-button {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}

::v-deep .el-dialog__body {
  height: 600px;
  overflow-y: scroll;
}

.province_style {
  margin-top: -20px;
  margin-bottom: 18px;

  .province_name {
    font-weight: 400;
    color: #8e8d8d;
  }
}
</style>
